.video-player {
  width: 640px;
  border-radius: 12px;
  box-shadow: 0px 0px 5px 5px rgba(218, 216, 216, 0.6);
  .play-btn {
    position: relative;
    z-index: 2;
    bottom: calc(215px + 15px);
    left: calc(320px - 40px);
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    &.hide {
      z-index: -2;
    }
  }
  .video-mask {
    border-radius: 12px;
    width: 640px;
    overflow: hidden;
    z-index: 0;
    position: relative;
    object-fit: cover;
    video {
      cursor: pointer;
      object-fit: cover;
    }
  }
  .video-footer {
    position: relative;
    bottom: 100px;
    .hidden {
      opacity: 0;
    }
    p {
      margin-bottom: 0px;
      color: white;
      font-family: $primary-font;
      font-size: 13px;
    }
    img {
      margin-left: 18px;
      margin-right: 5px;
      height: 15px;
    }
  }
  .video-tools {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: -75px;
    padding-bottom: 17px;
    border-bottom: 1px solid $grey-blue-color;
    img {
      cursor: pointer;
      max-height: 17px;
    }
    .play-control {
      max-height: 15px;
    }
  }
  .video-notes {
    margin: 10px;
    padding: 6px;
    min-height: 65px;
    font-family: $primary-font;
    font-size: 13px;
  }
}
.switch-off.btn-xs,
.switch-on.btn-xs {
  padding-left: 0.8rem;
  padding: 2px 0px;
  line-height: 28px;
}
