@keyframes bookmark-sidebar-appear {
  0% {
    transform: translateX(332px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes bookmark-sidebar-disappear {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(332px);
  }
}

.bookmark-sidebar-wrapper {
  background-color: white;
  position: absolute;
  top: 0vh;
  height: 100vh;
  z-index: 200;
  &.d-block {
    animation: bookmark-sidebar-appear 400ms linear;
    transform: translateX(0px);
    width: 332px;
    overflow-y: auto;
  }
  &.d-hide {
    animation: bookmark-sidebar-disappear 600ms linear;
    transform: translateX(400px);
    transform: scaleX(0);
  }
  .bookmark-header {
    border-bottom: 1px solid $grey-blue-color;
    font-family: $primary-font;
    font-size: 15px;
    font-weight: 700;
    color: $primary-blue-color;
    align-items: center;
    padding: 20px;
    p {
      margin-bottom: 0px;
    }
    img {
      cursor: pointer;
      height: 13px;
    }
  }
  .new-bookmark {
    padding: 20px;
    border-bottom: 1px solid $grey-blue-color;
    .timestamp {
      p {
        margin-bottom: 0px;
        font-family: $primary-font;
        font-size: 13px;
      }
      img {
        margin-top: 3px;
        margin-right: 6px;
        height: 13px;
      }
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 18px;
    }
    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 3px;
      bottom: 3.2px;
      background-color: white;
      -webkit-transition: 0.25s;
      transition: 0.25s;
    }
    input:checked + .slider {
      background-color: $third-blue-color;
    }
    input:focus + .slider {
      box-shadow: 0 0 1px $third-blue-color;
    }
    input:checked + .slider:before {
      -webkit-transform: translateX(11px);
      -ms-transform: translateX(11px);
      transform: translateX(11px);
    }
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
    textarea {
      // margin: 12px 0px 8px 0px !important;
      background-color: aliceblue !important;
      outline: 0;
      border: 1px solid $grey-blue-color;
      font-family: $primary-font !important;
      font-size: 13px !important;
      color: #444a58;
      overflow: auto !important;
      &:focus {
        border-color: royalblue;
        border: 1px solid royalblue;
      }
    }
    .mentions-input {
      margin: 12px 0px 8px 0px;
      padding: 5px 8px;
      min-height: 118px;
      background-color: $grey-three-color;
      border: 1px solid $grey-blue-color;
    }
    .mention-list {
      font-family: $primary-font !important;
      font-size: 13px !important;
      min-height: 118px;
    }
    .mention-list__suggestions {
      background-color: white !important;
      padding: 8px !important;
      z-index: 1000 !important;
      border: 1px solid rgb(126, 126, 126);
      border-radius: 4px;
      height: 200px;
      overflow-y: scroll;
    }
    .mention-list__suggestions__item {
      padding: 5px 5px;
      border-bottom: 1px solid $grey-sixth-color;
    }
    .action-button {
      .btn-primary {
        background-color: $fourth-blue-color;
        font-family: $primary-font;
        font-size: 16px;
        font-weight: 600;
        // padding: 8px 43px;
        width: 48%;
        height:40px;
        border-radius: 4px;
      }
      .cancel-btn {
        color: $primary-blue-color;
        border: 1px solid $primary-blue-color;
        // padding: 8px 30px;
        width: 48%;
        height:40px;
        font-weight: 600;
        font-family: $primary-font;
        border-radius: 4px !important;
      }
    }
  }
  .existing-bookmark {
    height: 100%;
    background-color: $sky-blue-color;
    padding: 16px 20px;
    .existing-bookmark-item {
      margin-bottom: 16px;
      border-bottom: 1px solid $grey-blue-color;
      padding-bottom: 16px;
      display: flex;
      flex-direction: column;
      .assessor-info {
        margin-right: 14px;
      }
      img {
        height: 14px;
        margin-right: 5px;
        margin-top: 2px;
      }
      p {
        font-family: $primary-font;
        font-size: 13px;
      }
      textarea {
        // margin: 12px 0px 8px 0px !important;
        background-color: $grey-three-color !important;
        outline: 0;
        border: none;
        font-family: $primary-font !important;
        font-size: 13px !important;
        color: #444a58;
        border-color: #ccc;
      }
      .mentions-input {
        margin: 12px 0px 8px 0px;
        padding: 5px 8px;
        min-height: 118px;
        background-color: $grey-three-color;
        border: 1px solid $grey-blue-color;
      }
      span {
        background-color: $primary-yellow-color;
        border-radius: 32px;
        margin-right: 8px;
        padding: 4px 10px;
        font-family: $primary-font;
        color: white;
        font-size: 13px;
        font-weight: 600;
        margin-top: -100px;
      }
    }
  }
}

.bookmark-item {
  padding-bottom: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(189, 189, 189);
  .assessor-info {
    margin-right: 14px;
  }
  img {
    height: 14px;
    margin-right: 5px;
    margin-top: 2px;
  }
  p {
    font-family: $primary-font;
    font-size: 13px;
  }
  span {
    background-color: $primary-yellow-color;
    border-radius: 4px;
    padding: 0 5px;
  }
  textarea {
    background-color: $grey-three-color !important;
    outline: 0px solid $grey-blue-color;
    border: 0px;
    font-family: $primary-font !important;
    font-size: 13px !important;
    color: #444a58;
    padding: 5px 8px;
  }

  .kompetensi-container {
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;

    & > * {
      margin-top: 5px;
      margin-left: 2px;
    }
  }

  .mentions-input {
    margin-bottom: 12px;
  }

  .mentions {
    padding: 10px;
  }
}

textarea {
  border-radius: 4px;
  padding: 5px;
}

.mentions-input {
  border-radius: 8px;
}