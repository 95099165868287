@import "../variables.scss";

.mobile-container {
  background-color: $mobile-background-color;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.mobile-top-bar {
  display: flex;
  justify-content: space-between;
  margin: 20px 27px 0px 56px;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  .mobile-top-bar-title{
    display: flex;
    align-items: center;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 8px;
  }
}

.mobile-top-bar-icon {
  display: flex;
  align-items: center;
  .margin-right {
    margin-right: 16px;
  }
}

.mobile-container-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 75px;
  height: calc(100vh - 200px);
}

.mobile-content {
  display: flex;
  // flex-direction: column;
  // margin: 46px 19px 0px 16px;
  margin: 28px 19px 0px 16px;
  overflow-y: auto;
  flex-wrap: wrap;
}

.mobile-container-materi-group {
  bottom: unset !important;
  top: 0;
  padding: 20px;
  z-index: 123123 !important;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100vh !important;
}

.mobile-materi-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #D6E6FF;
}

.mobile-materi-group-header-title {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 8px;
  color: #005589;
}

.mobile-materi-group-content {
  max-height: 50vh;
  overflow-y: auto;
  position: relative;
  // margin-bottom: 20px;
}

.mobile-materi-group-arrow {
  display: flex;
  justify-content: center;
  // position: sticky;
  /* width: 100%; */
  padding: 20px;
  background: #FFFFFF;
}

.mobile-max-height-materi-group-100 {
  max-height: calc(100vh - 72px) !important;
}

.mobile-max-height-materi-group-50 {
  max-height: 50vh !important;
}

.mobile-materi-group-attachment-title {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 8px;
  color: #005589;
  margin-bottom: 15px;
  margin-top: 20px;
}
.mobile-materi-group-attachment-content {
  display: flex;
  justify-content: flex-start;
  max-height: 30%;
  overflow-x: auto;
}

.mobile-materi-group-attachment-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  max-width: 25%;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #005589;
    margin-top: 10px;
  }
}

.mobile-attachment-show-image {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.90);
}

.mobile-share-screen-img-fgd {
  object-fit: cover;
}

.mobile-object-fit-contain {
  object-fit: contain !important;
}

.mobile-share-screen-items {
  width: auto !important;
}

.mobile-sticky-bottom {
  position: sticky !important;
  bottom: 0 !important;
}

.mobile-share-screen-img-one-on-one {
  object-fit: cover;
  height: 100% !important;
}

.mobile-share-screen-item {
  width: auto;
  position: relative;
}

.mobile-share-screen-item-video {
  height: auto;
}

.mobile-share-screen-item-name {
  height: auto;
}
// .mobile-share-screen-container {
//   // width: 100%;
//   height: 168px;
//   background: #FFFFFF;
//   border-radius: 4px;
//   display: flex;
//   flex-direction: column;
//   margin: 46px 27px -22px 24px;
// }

// .mobile-share-screen-video {
//   width: 100%;
//   height: 136px;
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// }

// .mobile-share-screen-name {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 32px;

//   font-family: $primary-font;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 16px;

//   color: #242424;
// }

.mobile-unread-chat {
  position: absolute;
  top: 10px;
  left: 27px;
  background-color: #de350b;
  height: 6.5px;
  width: 6.5px;
  border-radius: 100%;
}

.mobile-is-talking {
  border: 2px solid lime;
  border-radius: 12px;
}

// content one on one
.mobile-content-items-one-on-one {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // height: 232px;
  height: calc((100vh - 275px) / 2);
  margin-bottom: 16px;
  position: relative;
}

.mobile-content-item-one-on-one {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 8px;
}

.mobile-content-item-video-one-on-one {
  height: 85%;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
}

.mobile-content-item-name-one-on-one {
  display: flex;
  // height: 43.07px;
  height: 15%;
  width: 100%;
  align-items: center;
  color: $mobile-text-peserta;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: center;
  margin: auto;

  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

// content fgd

.mobile-content-items-fgd {
  display: flex;
  justify-content: space-between;
  width: 50%;
  height: 168px;
  margin-bottom: 16px;
  position: relative;
}

.mobile-content-item-fgd {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 8px;
}

.mobile-content-item-video-fgd {
  height: 136px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.mobile-content-item-name-fgd {
  display: flex;
  height: 32px;
  width: 100%;
  align-items: center;
  color: $mobile-text-peserta;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: center;
  margin: auto;

  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.mobile-content-arrow {
  align-items: center;
  margin: 25px auto 0px auto;
}

.mobile-footer {
  background-color: #FFFFFF;
  display: flex;
  height: 100px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 3;
  box-shadow: 0 0 20px #bbb;
}

.mobile-footer-items {
  margin : 33px 22px 23px 22px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  .mobile-footer-item {
    display: flex;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }
  .active {
    background-color: $mobile-dark-grey;
  }
  .not-active {
    background-color: $mobile-soft-grey;
  }
}

// modal
.mobile-container-modal {
  width: 100vw;
  z-index: 100;
  position: fixed;
  bottom: 0;
  bottom:0;
  background-color: #FFFFFF;
  border-radius: 8px 8px 0px 0px;
  margin: auto;
  left: 0;
  right: 0;
  max-height: 80vh;
  box-shadow: 0 5px 20px #333
  // padding-bottom: 100px;
}

.mobile-list-participant-close {
  right: 8px;
  position: absolute;
}

.mobile-modal-title {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

// list participant

.mobile-list-participants {
  margin: 25px;
  max-height: 50vh;
  overflow: auto;
}

.mobile-list-participans-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.mobile-list-participans-items {
  display: flex;
  flex-direction: column;
}

.mobile-list-participans-item {
  margin: 6px 0px;
  display: flex;
  justify-content: space-between;
}

.mobile-list-participant-name {
  display: flex;
  align-items: center;
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

/* Black */

color: #242424;
}

.mobile-list-participant-items {
  margin-right: 8px;
}

// chat
.mobile-list-chats {
  // margin: 25px;
  padding: 25px;
  max-height: 50vh;
  overflow: auto;
  background-color: #FFFFFF;
}

.mobile-max-height-30 {
  max-height: 30vh !important;
}

.mobile-max-height-100 {
  max-height: 100vh !important;
}

.mobile-max-height-50 {
  max-height: 50vh !important;
}

.mobile-list-chats-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.mobile-list-chats-items {
  display: flex;
  flex-direction: column;
}

.mobile-list-chats-item{
  margin: 6px 0px;
  display: flex;
  flex-direction: column;
}

.mobile-align-items-flex-start {
  align-items: flex-start;
}

.mobile-align-items-flex-end {
  align-items: flex-end;
}

.mobile-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.mobile-margin-left-16px {
  margin-left: 16px;
}

.mobile-time-chat {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  /* Grey */

  color: #A5ADBA;
}

.mobile-margin-right-16px {
  margin-right: 16px;
}

.mobile-list-chat-header{
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.mobile-list-chat-item-item {
  margin: 0px 4px;
}

.mobile-chat-name-me {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #005589;
}

.mobile-chat-name-other {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #FD9F28;
}

.mobile-chat-content {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: justify;
  white-space: pre-line;
}

.mobile-margin-left-30 {
  margin-left: 30%;
}

.mobile-margin-right-30 {
  margin-right: 30%;
}

.mobile-bottom-40 {
  bottom: 40px !important;
}

.mobile-bottom-10 {
  bottom: 10px !important;
}

.mobile-bottom-0 {
  bottom: 0 !important;
}

.mobile-top-0 {
  top: 0 !important;
}

.mobile-chat-form {
  display: flex;
  // margin-top: 30px;
  position: sticky;
  // bottom: 0;
  bottom: calc(70px + (100vh - 100%));
  margin: 25px;
  background-color: #FFFFFF;
}

.mobile-container-share-screen {
  width: 100%;
  height: auto;
}

.mobile-chat-form > div {
  align-items: center;
  justify-content: center;
}

.mobile-chat-input-container {
  flex-grow: 1;
  margin-right: 18px;
}

.mobile-chat-input {
  height: 44px;
  border-radius: 4px;
  border: 1px solid #A5ADBA;
  width: 100%;
  vertical-align: middle;
}

.mobile-chat-button-send {
  display: flex;
  background: #005589;
  border-radius: 4px;
  width: 60px;
  height: 44px;
  margin: auto;
}

.mobile-full-screen {
  width: 100vw !important;
  position: fixed;
  height: 100% !important;
  top: 0;
  left: 0;
  z-index: 999;
  background: #FFFFFF;
}

.mobile-expand-screen-button {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  .img1 {
    margin-left: 50%;
    width: 50%;
  }
  .img2 {
    margin-right: 50%;
    width: 50%;
  } 
}

.mobile-container-content-one-on-one {
  flex-direction: column !important;
  align-items: normal !important;
}

@media (orientation: landscape) {
  .mobile-container-content {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    align-items: baseline;
    margin-bottom: 0px;
    height: auto;
    justify-content: center;
  }

  .mobile-container-share-screen {
    width: auto;
    height: 100%;
  }
  

  .mobile-content-arrow {
    margin: 0px;
    margin-right: 20px;
  }

  .mobile-content {
    display: flex;
    flex-direction: row;
    flex-flow: row nowrap;
    // margin: 46px 19px 100px 16px;
    overflow-x: auto;
    justify-content: center;
    align-items: center;
  }

  .mobile-content::before, .mobile-content::after {
    content: '';  /* Insert pseudo-element */
    margin: auto; /* Make it push flex items to the center */
    justify-content: flex-start;
  }

  // content one on one
  .mobile-share-screen-item {
    width: 267px !important;
    height: 194px !important;
    position: relative;
  }
  
  .mobile-share-screen-item-video {
    height: 157px !important;
  }

  .mobile-share-screen-item-name {
    height: 37px !important;
  }

  .mobile-share-screen-items {
    height: 194px !important;
  }

  .mobile-width-100 {
    width: 100% !important;
  }



  .mobile-content-items-one-on-one {
    justify-content: space-between;
    width: 30%;
    height: 177.7px;
    margin-bottom: 16px;
  }

  .mobile-content-item-one-on-one {
    height: 177.7px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px 8px;
  }

  .mobile-content-item-video-one-on-one {
    height: 144.64px;
    width: 100%;
    img {
      width: 100%;
      height: 144.64px;
    }
  }

  .mobile-content-item-name-one-on-one {
    display: flex;
    height: 33.06px;
    width: 100%;
    align-items: center;
    color: $mobile-text-peserta;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: center;
    margin: auto;

    font-family: $primary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  // fgd
  .mobile-content-item-fgd {
    width: 151.51px;
  }

  .mobile-content-items-fgd {
    width: auto;
  }

  .mobile-footer {
    height: 80px;
  }

  .mobile-footer-items {
    margin : 33px 22px 23px 22px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    max-width: 390px;
    margin: auto;
    .mobile-footer-item {
      display: flex;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }
    .active {
      background-color: $mobile-dark-grey;
    }
    .not-active {
      background-color: $mobile-soft-grey;
    }
  }

  .mobile-container-modal {
    max-width: 390px;
    max-height: 80%;
  }
}


.fade-in-image {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}



