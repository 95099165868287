.modal-warning {
    position: absolute;
    z-index: 2;
    top: 30vh;
    left: calc(50vw - 235px);
    font-family: $primary-font;
    font-size: 16px;
    background-color: white;
    min-width: 471px;
    border-radius: 12px;
    .modal-body {
        min-width: 473px;
        padding: 33px 31px;
        .main-message {
            margin-bottom: 25px;
            color: $dark-grey-color;
            display: flex;
            justify-content: center;
        }
        .action-btn {
            margin-top: 0px;
            .cancel-btn {
                background-color: $seventh-blue-color;
                color: $primary-blue-color;
                height: 32px;
                width: 196px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
            }
            .confirm-btn {
                background-color: $primary-red-color;
                color: white;
                height: 32px;
                width: 196px;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
            }
        }
    }
}