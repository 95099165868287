.waiting-control {
    justify-content: center;
    margin-top: 30px;
    font-family: $primary-font;
    .device-options {
        width: 24vw;
        .menu-title {
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 15px;
            color: $black-menu-color;
        }
        .device-menu-item {
            margin-bottom: 20px;
            padding-bottom: 20px;
            .device-status {
                font-size: 14px;
                font-weight: 400;
                font-family: $primary-font;
                margin-bottom: 0px;
            }
            &.last {
                border-bottom: 0px;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
            .speaker-test {
                cursor: pointer;
                margin-top: 10px;
                font-size: 14px;
                font-family: $primary-font;
                color: $primary-blue-color;
                font-weight: 600;
                &.playing {
                    cursor: default;
                    color: $grey-sixth-color;
                }
            }
            img {
                margin-top: 4px;
                margin-right: 10px;
                margin-left: 2px;
                height: 14px;
            }
        }
        .select-menu {
            margin-top: -10px;
        }
        #select-menu {
            margin-top: 0px;
            width: calc(20vw);
            font-family: $primary-font;
            font-size: 14px;
            font-weight: 600;
            color: $primary-blue-color;
        }
    }
}