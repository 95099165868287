.edit-bookmark-modal {
  overflow: hidden;
  position: absolute;
  top: calc(30vh);
  z-index: 5;
  left: 345px;
  font-family: $primary-font;
  font-size: 13px;
  font-weight: 400;
  width: 360px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 10px #000;
  .modal-body {
    padding: 20px;
    .edit-bookmark-header {
      &:active{
        cursor: move;
      }
      img {
        height: 15px;
        margin-right: 6px;
      }
      p {
        margin-bottom: 0px;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 18px;
      }
      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 3px;
        bottom: 3.2px;
        background-color: white;
        -webkit-transition: 0.25s;
        transition: 0.25s;
      }
      input:checked + .slider {
        background-color: $third-blue-color;
      }
      input:focus + .slider {
        box-shadow: 0 0 1px $third-blue-color;
      }
      input:checked + .slider:before {
        -webkit-transform: translateX(11px);
        -ms-transform: translateX(11px);
        transform: translateX(11px);
      }
      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }
      .slider.round:before {
        border-radius: 50%;
      }
    }
    .search-skill {
      margin-bottom: 0px;
      .MuiInputBase-root {
        font-family: $primary-font;
        font-size: 13px;
        height: 31px;
        padding: 0px;
        max-height: 210px;
        top: 3px;
        .free-solo-2-demo {
          height: 31px;
          padding: 0px 10px 0px 33px;
          margin-top: -8px;
          background-color: $grey-three-color;
        }
        .free-solo-2-demo-popup .MuiFormControl-root {
          padding: 0px;
          background-color: $grey-three-color;
          max-height: 210px;
        }
        .MuiFormControl-marginNormal {
          margin: 0px;
          background-color: $grey-three-color;
          max-height: 210px;
        }
      }
      img {
        position: relative;
        top: -37px;
        left: 10px;
        height: 15px;
      }
    }
    .selected-skill-wrapper {
      margin-top: -18px;
      .selected-skill {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        background-color: $third-blue-color;
        border-radius: 32px;
        color: white;
        padding: 5px 14px;
        margin-bottom: 12px;
        p {
          margin-bottom: 0px;
          font-weight: 600;
        }
        img {
          height: 15px;
        }
      }
    }
    textarea {
      outline: 0px solid $grey-blue-color;
      border: 0px;
      color: $dark-grey-color;
      background-color: $grey-three-color;
      margin-bottom: 12px;
      min-width: 100%;
    }
    .mentions-input {
      min-height: 93px;
      background-color: aliceblue;
      &:focus {
        border-color: royalblue;
      }
      border: 1px solid $grey-blue-color;
    }
    .mention-list {
      min-height: 93px;
    }
    .mentions-input__suggestions {
      background-color: white !important;
      padding: 8px !important;
      z-index: 200;
      height: 200px;
      overflow-y: scroll;
    }
    .mentiosn-input__suggestions__item {
      padding: 5px 5px;
      border-bottom: 1px solid $grey-sixth-color;
    }
    .remove {
      color: $primary-red-color;
    }
  }
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding-top: 4px !important;
}