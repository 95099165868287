.highlight-header {
  font-family: $primary-font;
  max-height: 110px;
  .auto-save {
    margin: 23px 0px 0px 0px;
    font-family: $primary-font;
    font-size: 12px;
    justify-content: center;
    p {
      margin-bottom: 0px;
      color: $dark-grey-color;
      .success {
        color: $blue-header-title;
      }
      .failed {
        color: red;
      }
    }
  }
  .full-timeline {
    max-width: 66vw;
    position: relative;
    top: -96px;
    left: 116px;
    height: 20px;
    .thumb {
      z-index: 3;
      height: 18px;
      width: 2px;
      background-color: black;
    }
    .marker-area {
      position: relative;
      height: 9px;
      background-color: $fifth-blue-color;
      z-index: 5;
    }
  }
  .video-timeline {
    padding: 6px 38px 50px 0px;
    margin-bottom: 1rem;
    input {
      width: 66vw;
      margin-right: 12px;
      margin-left: 12px;
    }
    p {
      font-size: 13px;
      margin-bottom: 0px;
    }
  }
  .btn-action {
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    &.undo {
      background-color: $fifth-blue-color;
      margin-right: 16px;
    }
    &.redo {
      background-color: $seventh-blue-color;
      margin-right: 24px;
    }
  }
  .save-button {
    margin-left: 20px;
    margin-right: 143px;
    background-color: $primary-blue-color;
    min-width: 98px;
    min-height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    color: white;
    box-shadow: 0px 0px 3px 3px rgba(202, 203, 255, 0.6);
    font-size: 14px;
    font-weight: 600;
    font-family: $primary-font;
    cursor: pointer;
  }
  .material-slider {
    background-color: $grey-five-color;
    width: 66vw;
    height: 13px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .MuiSlider-root {
    margin-top: -8px;
    min-width: 66vw;
    display: flex;
    align-items: center;
  }
  .MuiSlider-rail {
    // position: relative;
    height: 13px;
    z-index: 7;
    opacity: 0;
  }
  .MuiSlider-track {
    position: relative;
    z-index: 1;
    height: 13px;
    background-color: $sixth-blue-color;
  }
  .MuiSlider-thumb {
    margin-left: 0px;
    margin-top: 0px;
    height: 13px;
    width: 2px;
    border-radius: 0px;
    background-color: black;
    z-index: 10;
  }
}
