.modal-backdrop {
  background-color: $grey-backdrop-color !important;
  opacity: 0.5 !important;
}
.modal-review-highlight {
  font-family: $secondary-font;
  min-width: 65vw;
  .modal-dialog {
    margin-top: 13vh;
    min-width: 65vw;
    height: 75vh;
    border: 0px solid;
  }
  .modal-header {
    font-size: 20px;
    font-weight: 700;
    color: $black-menu-color;
    padding: 24px 20px 20px 24px;
    font-family: $primary-font;
  }
  .modal-body {
    height: calc(75vh - 72px - 64px);
    overflow-y: auto;
    padding: 24px 0px 0px 0px;
    .highlight-item {
      margin-bottom: 20px;
      border-bottom: 1px solid $grey-border-color !important;
      padding: 0px 24px;
      .video-preview {
        margin-top: -28px;
        img {
          position: relative;
          top: 72px;
          left: 77px;
          z-index: 5;
        }
        .hide {
            opacity: 0;
        }
        .review-video {
          min-width: 182px;
          max-width: 182px;
          margin-bottom: 20px;
          height: 117px;
          margin-right: 24px;
          overflow: hidden;
          z-index: 0;
          position: relative;
          border-radius: 4px;
          video {
            object-fit: cover;
            height: 117px !important;
            width: 182px !important;
            cursor: pointer;
          }
        }
      }
      .review-description {
        .description-header {
          color: $dark-grey-color;
          font-size: 11px;
          .timestamp {
            margin-right: 5px;
          }
          .competencies {
            margin-left: 5px;
          }
        }
        .description-title {
          margin-top: 4px;
          font-size: 16px;
          color: $primary-blue-color;
          font-weight: 600;
        }
        .description-content {
          font-size: 14px;
          font-weight: 300;
          font-style: italic;
          margin-bottom: 12px;
          margin-top: 8px;
          flex-wrap: wrap;
        }
        .description-footer {
          img {
            height: 11px;
            margin-right: 8px;
            margin-top: 3px;
          }
          p {
            margin-bottom: 0px;
          }
          font-size: 12px;
          color: $grey-footer-review;
        }
      }
      img {
        max-width: 13vw;
      }
    }
  }
  .modal-footer {
    font-family: $primary-font;
    font-size: 14px;
    button {
      padding: 6px 77px;
    }
  }
}
