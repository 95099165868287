.question-list-modal {
    background-color: white;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    top: 70px;
    left: calc(100vw - 700px);
    width: 280px;
    .modal-header {
        font-family: $primary-font;
        color: $primary-blue-color;
        font-weight: 700;
        font-size: 15px;
        border-bottom: 1px solid $grey-blue-color;
        cursor: pointer;
        img {
            cursor: pointer;
        }
        p {
            margin-bottom: 0px;
        }
        &:active{
            cursor: move;
        }
    }
    .modal-body {
        padding: 16px;
        font-family: $primary-font !important;
        font-size: 13px;
        input {
            margin-top: 2px;
            margin-right: 6px;
            margin-bottom: 11px;
            min-width: 15px;
            min-height: 15px;
            border: 2px solid $grey-two-color;
        }
        label {
            margin-bottom: 11px;
        }
    }
}