.chatroom-modal {
    font-family: $primary-font;
    position: fixed;
    z-index: 1;
    margin-left: calc(100vw - 350px);
    margin-top: 70px;
    background-color: white;
    width: 320px;
    border-radius: 4px;
    .modal-header {
        color: $primary-blue-color;
        font-size: 14px;
        font-weight: 700;
        height: 46px;
        border-bottom: 1px solid $grey-blue-color;
        cursor: pointer;
        p {
            margin-bottom: 0px;
        }
        img {
            cursor: pointer;
        }
        &:active{
            cursor: move;
        }
    }
    .modal-body {
        padding: 4px 12px 9px 14px;
        .received-chat {
            margin-top: 8px;
            margin-bottom: 8px;
            img {
                height: 25px;
                margin-top: auto;
                margin-bottom: 5px;
            }
            .received-dialog {
                background-color: #f3f2f7;
                font-family: $primary-font;
                font-size: 13px;
                border-radius: 4px;
                width: 233px;
                padding: 4px 9px 7px 9px;
                .received-name {
                    font-weight: 400;
                    margin: 0px 0px 0px 0px;
                }
                .received-text {
                    font-weight: 600;
                    margin: 0px;
                    white-space: pre-line;
                }
                .received-time {
                    display: flex;
                    justify-content: flex-end;
                    margin: 10px 0px 0px 0px;
                    font-size: 9px;
                }
            }
        }
        .sent-chat {
            margin-top: 8px;
            margin-bottom: 8px;
            img {
                height: 15px;
                margin-top: auto;
                margin-bottom: 5px;
            }
            .sent-dialog {
                background-color: rgba(223,241,255,255);
                font-family: $primary-font;
                font-size: 13px;
                border-radius: 4px;
                width: 233px;
                padding: 4px 9px 7px 9px;
                .sent-text {
                    font-weight: 600;
                    margin: 0px;
                    white-space: pre-line;
                }
                .sent-time {
                    display: flex;
                    justify-content: flex-end;
                    margin: 10px 0px 0px 0px;
                    font-size: 9px;
                    p {
                        margin-bottom: 0px;
                    }
                }
            }
        }
        textarea {
            margin: 20px 0px 0px 0px;
            background-color: $grey-three-color;
            outline: 1px solid $grey-blue-color;
            border: 0px;
            color: #5A5A5A;
            width: 100%;
            font-size: 13px;
        }
    }
}