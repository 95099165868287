.speech-to-text {
  height: calc(100vh - 170px);
  width: 100%;
  overflow-y: scroll;
  .speaker-transcript-wrapper {
    min-width: 400px;
    width: calc(100vw - 64px - 650px - 220px);
    .speaker-transcript {
      font-family: "Open Sans";
      margin-left: 25px;
      margin-bottom: 16px;
      width: 100%;
      .transcript-header {
        margin-top: 5px;
        .cursor {
          margin-top: 2px;
          height: 13px;
          margin-right: 13px;
          cursor: pointer;
        }
        .person {
          height: 16px;
          margin-right: 5px;
        }
        p {
          margin-top: 1px;
          margin-bottom: 0px;
          font-size: 13px;
          font-weight: 600;
          color: $grey-sixth-color;
        }
      }
      .transcript-content {
        .word {
          margin-bottom: -3px;
        }
        flex-wrap: wrap;
        font-size: 13px;
        line-height: 32px;
        max-width: 100%;
        p {
          width: 100%;
        }
        mark {
          background-color: $grey-blue-color;
        }
      }
    }
  }
  .bookmark {
    .bookmark-line {
      height: 100px;
      width: 4px;
      background-color: $grey-seventh-color;
      // border-radius: 2px;
      margin-left: 31px;
      .icon-wrapper {
        cursor: pointer;
        height: 24px;
        min-width: 24px;
        border-radius: 50%;
        background-color: $grey-blue-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        margin-right: 8px;
        &.active {
          background-color: $fifth-blue-color;
        }

        &.bookmark {
          background-color: $grey-red-color;

          &.active {
            background-color: $primary-red-color;
          }
        }
      }
      .skill-number {
        background-color: $primary-yellow-color;
        padding: 0px 10px;
        height: 24px;
        color: white;
        margin-bottom: 7px;
        border-radius: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 13px;
        }
      }
    }
  }
}
.disable-scroll-parent {
  overflow-y: hidden;
}

.isWordBookmarked {
  background: $grey-red-color !important;
}

// big monitors
@media screen and (min-width: 1900px) {
  .speaker-transcript-wrapper {
    max-width: calc(100vw - 64px - 650px - 400px);
  }
}
