.waiting-room-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $primary-font;
    margin-top: 50px;
    .content-header {
        h1 {
            color: $primary-blue-color;
            font-weight: 700;
            font-size: 32px;
            margin-bottom: 12px;
        }
        .subtitle {
            justify-content: center;
            color: $black-menu-color;
            img {
                height: 24px;
            }
        }
    }
    .video {
        margin-top: -5px;
        .user-info {
            position: relative;
            top: 45px;
            left: 10px;
            color: white;
            font-size: 16px;
            font-weight: 600;
            img {
                margin-left: 5px;
                max-height: 19px;
            }
        }
        .user-video {
            background-color: black;
            img {
                height: 47vh;
                width: 48vw;
                object-fit: cover;
            }
            video {
                height: 47vh;
                width: 48vw;
                object-fit: cover;
            }
            height: 47vh;
            width: 48vw;
            .no-video {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: auto;
                margin: auto;
                width: 16vh;
                height: 16vh;
                border-radius: 50%;
                background-color: $primary-yellow-color;
                color: white;
                font-size: 8vh;
                font-weight: 600;
                position: relative;
                top: calc(47vh / 2 - 8vh);
            }
        }
    }
}