.box {
  height: 100%;
  color: white;
  background: rgb(68, 68, 68);
  border-radius: 12px;
  position: relative;

  &.is-talking {
    // border: 4px solid $primary-yellow-color;
    border: 4px solid lime;
  }

  &.no-talking {
    // border: 4px solid $primary-yellow-color;
    border: 4px solid #000;
  }
}

.grid-container {
  width: 90%;
  display: grid;
  overflow: scroll;
  grid-gap: 10px;
  justify-content: center;
}

.more-two {
  max-height: calc(100vh - 8rem);
}
.more-two.sharing {
  margin-top: 15px;
}

.main.sharing {
  animation: show-share-screen 500ms ease;
  transform: translateX(0);
  width: 400px;
}

.grid-container .box-wrapper-1,
.box-wrapper-2 {
  grid-column: span 2;
  display: flex;
  justify-content: center;
}

.grid-container .box-wrapper-3 {
  grid-column: span 3;
  display: flex;
  justify-content: center;
}

.grid-container .box-wrapper-4 {
  grid-column: span 4;
  display: flex;
  justify-content: center;
}

.grid-container .box-wrapper-3 > * {
  margin: 0px 5px;
}

.grid-container .box-wrapper-4 > * {
  margin: 0px 5px;
}

.grid-container .box-wrapper .box {
  width: 60%;
}

.grid-container .box-wrapper-3 .box {
  width: 40%;
}

.grid-container .box-wrapper-4 .box {
  width: 30%;
}

.box-wrapper.sharing {
  grid-column: unset;
  display: unset;
  justify-content: unset;
}

.box-wrapper.sharing .box {
  width: 100%;
}

.box-wrapper-hide > * {
  margin-bottom: 10px;
}

.box-wrapper.sharing > * {
  margin-bottom: 10px;
}

.grid-container.video-1 {
  grid-template-columns: 70vw;
  grid-template-rows: repeat(auto-fill, calc(((100vh - 8rem)) - 10px));
}

.grid-container.video-1 .box {
  max-height: calc(((100vh - 8rem)) - 10px);
}

.grid-container.video-1.sharing {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(auto-fill, 240px);
}

.grid-container.video-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: calc(((100vh - 8rem) * 0.75) - 10px);
  .box {
    height: calc(((100vh - 8rem) * 0.75) - 10px);
  }
}

.grid-container.video-2.sharing {
  grid-template-columns: repeat(1, 420px);
  grid-template-rows: repeat(auto-fill, 240px);
  @media screen and (max-width: 1536px) {
    grid-template-columns: repeat(1, 300px);
  }
  .box {
    height: 240px;
  }
}

.grid-container.video-4 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, calc(((100vh - 8rem) / 2) - 10px));
}

.grid-container.video-4 .box {
  max-height: calc(((100vh - 8rem) / 2) - 10px);
}

.grid-container.video-4.sharing {
  grid-template-columns: 340px;
  @media screen and (max-width: 1536px) {
    grid-template-columns: 240px;
  }
  grid-template-rows: repeat(auto-fill, calc(((100vh - 8rem) / 4) - 10px));
}

.grid-container.video-6 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, calc(((100vh - 12rem) / 2) - 10px));

  .box {
    max-height: calc(((100vh - 12rem) / 2) - 10px);
  }
}

.grid-container.video-6.sharing {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, calc(((100vh - 12rem) / 4) - 10px));
  & > * > .box {
    width: calc(50% - 5px);
    max-height: calc(((100vh - 12rem) / 4) - 10px);
  }
}

.video-8.sharing.name-display {
  max-width: 100px;
}

.grid-container.video-8 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, calc(((100vh - 8rem) / 3) - 10px));
}

.grid-container.video-8 .box {
  max-height: calc(((100vh - 8rem) / 3) - 10px);
}

.grid-container.video-8.sharing {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, 150px);
  .box {
    height: 150px;
  }
  & > * > .box {
    width: calc(50% - 5px);
  }
}

.grid-container.video-12 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, calc(((100vh - 8rem) / 3) - 10px));
  .box {
    max-height: calc(((100vh - 8rem) / 3) - 10px);
  }
}
.grid-container.video-12.sharing {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, 150px);
  .box {
    height: 150px;
  }
  & > * > .box {
    width: calc(50% - 5px);
  }

  .trim-name {
    font-size: 12px;
    // width: 100px;
  }

  .no-photo {
    height: 70px;
    width: 70px;
    h4 {
      font-size: 3vh;
    }
  }
}

.grid-container.video-16 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, calc(((100vh - 8rem) / 4) - 10px));
}

.grid-container.video-16 .box {
  max-height: calc(((100vh - 8rem) / 4) - 10px);
}

.grid-container.video-16.sharing {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fill, 150px);
  & > * > .box {
    width: calc(50% - 5px);
    max-height: 150px;
  }
}

.sharing.box-wrapper-hide {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-column: span 2;
}

.sharing.box-wrapper-hide .box {
  width: 195px;
}

.video-4 {
  .sharing.box-wrapper-hide {
    grid-column: unset;
    .box {
      width: 100%;
    }
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.box-wrapper,
.box-wrapper-1,
.box-wrapper-2,
.box-wrapper-3,
.box-wrapper-4,
.box {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}

@keyframes show-share-screen {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0px);
  }
}

.trim-name {
  // max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name-display {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 80%;
  h5 {
    font-family: $google-sans-font;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #fff;
    letter-spacing: 0.2px;
    z-index: 1000;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    background-color: rgba(0, 0, 0 , 0.2);
    border-radius: 8px;
    padding: 0 5px;
  }
  // img {
  //   margin-top: 3px;
  //   height: 20px;
  //   width: unset;
  // }
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  // min-width: 30%;
  .no-photo {
    text-align: center;
    height: 80px;
    width: 80px;
    background-color: $primary-yellow-color;
    border-radius: 50%;
    h4 {
      font-size: 5vh;
    }
  }

  .participant-mute {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
    .peserta-utils-container {
      background: rgba(0, 0, 0, 0.6);
      border-radius: 40px;
      padding: 12px;
    }

    .utils-icon {
      border-radius: 100%;
      height: 48px;
      width: 48px;
      padding: 10px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.3);
      &:hover {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.conference {
  display: flex;
  flex: 1;
  border-radius: 10px;
  gap: 20px;
  max-height: 80%;
  max-width: 100%;
}

.dish {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex: 1;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
}

.icon-mic-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  background: rgba(0,0,0,.3);
  border-radius: 50%;
  z-index: 1000
}

.icon-mic-wrapper-mobile {
  position: absolute;
  right: 15px;
  top: 10px;
  background: rgba(0,0,0,.6);
  border-radius: 50%;
  z-index: 1000
}