.lobby-header {
    background-color: white;
    height: 61px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: $primary-font;
    font-weight: 600;
    font-size: 14px;
    .user-profile {
        align-items: center;
        .user-photo {
            cursor: pointer;
            height: 41px;
            width: 41px;
            border-radius: 50%;
            background-color: $primary-yellow-color;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 600;
            font-size: 15px;
        }
        .user-name {
            cursor: pointer;
            margin-bottom: 0px;
            margin-right: 7px;
            color: $grey-header-text;
        }
        .arrow {
            color: $primary-blue-color;
            cursor: pointer;
        }
    }
}