.remap-speakers {
  margin-top: 30px;
  padding: 20px;
  width: 640px;
  border-radius: 12px;
  box-shadow: 0px 0px 5px 5px rgba(218, 216, 216, 0.6);
}

.left-highlight {
  overflow: auto;
  height: calc(100vh - 170px);
  padding: 1vh 1vh 2vh;
  min-width: 42em;
  margin-bottom: 1000vh;
}
