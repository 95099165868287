.competency-dictionary-modal {
    position: absolute;
    top: 100px;
    z-index: 5;
    left: 120px;
    width: 500px;
    min-height: 430px;
    border-radius: 12px;
    font-family: $primary-font;
    background-color: white;
    box-shadow: 0px 0px 3px 3px rgba(202, 203, 255, 0.9);
    .modal-header {
        padding: 0px;
        margin: 21px 21px 17px 21px;
        border-bottom: 0px;
        font-weight: 700;
        font-size: 13px;
        img {
            cursor: pointer;
        }
        p {
            margin-bottom: 0px;
        }
    }
    .modal-body {
        margin: -12px 21px 21px 5px;
        padding: 0px;
        font-size: 13px;
        .competency-list {
            min-width: 250px;
            max-height: 360px;
            overflow-y: scroll;
            list-style-type: none;
            .cluster {
                font-weight: 400;
                color: $dark-grey-color;
            }
            .competency {
                cursor: pointer;
                font-weight: 700;
                color: $black-menu-color;
                &.active {
                    background-color: $seventh-blue-color;
                    color: $primary-blue-color;
                    font-weight: 600;
                }
            }
            ul {
                margin-left: 0px;
                padding-left: 0.6em;
                list-style-type: none;
            }
            li {
                display: block;
                margin-left: 0px;
                padding-left: 0.6em;
                margin-bottom: 12px;
                margin-top: 12px;
                list-style-type: none;
            }
            li:before {
                margin-bottom: 12px;
            }
        }
        .competency-detail {
            padding: 12px;
            max-height: 360px;
            overflow-y: scroll;
            .detail-title {
                font-weight: 700;
                margin-bottom: 6px;
            }
        }
    }
}