// font
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Google+Sans:wght@100;200;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap');

@import "~bootstrap/scss/bootstrap";

// pages
@import "./assets/sass/pages/room.scss";
@import "./assets/sass/pages/highlight.scss";
@import "./assets/sass/pages/lobby.scss";
@import "./assets/sass/pages/mobileroom.scss";

// modals
@import "./assets/sass/modals/modals.scss";
@import "./assets/sass/modals/moreMenuModal.scss";
@import "./assets/sass/modals/chatroomModal.scss";
@import "./assets/sass/modals/questionList.scss";
@import "./assets/sass/modals/FGDPopup.scss";
@import "./assets/sass/modals/findAndReplace.scss";
@import "./assets/sass/modals/competencyDictionary.scss";
@import "./assets/sass/modals/editBookmark.scss";
@import "./assets/sass/modals/warningModal.scss";
@import "./assets/sass/modals/highlightReview.scss";

// components
@import "./assets/sass/components/bookmark.scss";
@import "./assets/sass/components/common.scss";
@import "./assets/sass/components/sharedScreen.scss";
@import "./assets/sass/components/highlightVideo.scss";
@import "./assets/sass/components/highlightHeader.scss";
@import "./assets/sass/components/transcript.scss";
@import "./assets/sass/components/lobbyHeader.scss";
@import "./assets/sass/components/waitingRoomContent.scss";
@import "./assets/sass/components/waitingRoomControl.scss";
@import "./assets/sass/components/sessionFeedback.scss";
@import "./assets/sass/components/footerSessionEnds.scss";
@import "./assets/sass/components/infoSessionEnds.scss";
@import "./assets/sass/components/newroom.scss";
@import "./assets/sass/components/remapSpeakers.scss";
