.more-menu-modal {
    width: 301px;
    position: absolute;
    z-index: 5;
    top: 70px;
    right: 6px;
}

.more-menu-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 21px;
    z-index: 5;
    height: calc(100vh - 80px);
    background-color: $white-theme;
    border: 0px;
    border-radius: 4px;
    .menu-item-active {
        color: $primary-blue-color;
        .pop-up-menu-item {
            cursor: pointer;
            color: $primary-blue-color;
        }
    }
    .pop-up-menu-title {
        font-family: $primary-font;
        font-size: 13px;
        font-weight: 700;
        align-items: center;
        margin-bottom: 20px;
        p {
            margin-bottom: 0px;
            color: $black-menu-color;
        }
        img {
            height: 11px;
            cursor: pointer;
        }
    }
    .pop-up-menu-item {
        font-size: 14px !important;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        font-family: $primary-font;
        margin-bottom: 8px;
        color: $black-menu-color;
        img {
            margin-top: 2px;
            height: fit-content;
            margin-right: 10px;
        }
    }
    .device-menu-item {
        border-bottom: 1px solid $grey-blue-color;
        margin-bottom: 20px;
        padding-bottom: 20px;
        .device-status {
            font-size: 14px;
            font-weight: 400;
            font-family: $primary-font;
            margin-bottom: 0px;
        }
        &.last {
            border-bottom: 0px;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }
        .speaker-test {
            cursor: pointer;
            margin-top: 10px;
            font-size: 14px;
            font-family: $primary-font;
            color: $primary-blue-color;
            font-weight: 600;
            &.playing {
                cursor: default;
                color: $grey-sixth-color;
            }
        }
        img {
            margin-top: 4px;
            margin-right: 10px;
            margin-left: 2px;
            height: 14px;
        }
    }
    .select-menu {
        margin-top: -10px;
    }
    #select-menu {
        margin-top: 0px;
        min-width: 220px;
        font-family: $primary-font;
        font-size: 14px;
        font-weight: 600;
        color: $primary-blue-color;
    }
}