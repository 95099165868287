@import "../variables.scss";
.App {
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
  ::-webkit-scrollbar-track {
    background: #fafafd !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #bbb !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #333 !important;
  }
}

body {
  overflow: hidden;
}

.room-wrapper {
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
  ::-webkit-scrollbar-track {
    background: #fafafd !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #bbb !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #333 !important;
  }
  height: 100vh;
  max-width: 100vw;
  background-color: black;
  .toast-join {
    .MuiSnackbarContent-root {
      color: $fifth-blue-color;
      background-color: $grey-eighth-color;
      border: 1px solid $fifth-blue-color;
      font-family: $primary-font;
      font-size: 13px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .MuiSnackbarContent-message {
      padding: 0px;
    }
  }
  .toast-left {
    .MuiSnackbarContent-root {
      color: $primary-red-color;
      border: 1px solid $primary-red-color;
      background-color: $grey-eighth-color;
      font-family: $primary-font;
      font-size: 13px;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
    }
    .MuiSnackbarContent-message {
      padding: 0px;
    }
  }
}

.toast-left {
  top: -200px;
  .MuiSnackbarContent-root {
    color: $primary-red-color;
    border: 1px solid $primary-red-color;
    background-color: $grey-eighth-color;
    font-family: $primary-font;
    font-size: 13px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }
  .MuiSnackbarContent-message {
    padding: 0px;
  }
}

@keyframes show-bookmark-animation {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes hide-bookmark-animation {
  0% {
    transform: translateX(-600px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes show-share-screen {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes hide-share-screen {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}

.twilio-wrapper {
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &.show-bookmark {
    max-width: calc(100% - 332px);
    animation: show-bookmark-animation 400ms ease;
    transform: translateX(0px);
    transform-origin: 100% 50%;
    position: absolute;
    right: 332px;
    .video3,
    .video4 {
      height: 37vh;
      width: 37vw;
      .no-photo {
        max-height: 22vh;
        max-width: 22vh;
        h4 {
          font-size: 10vh !important;
        }
      }
    }
  }
  &.hide-bookmark {
    animation: hide-bookmark-animation 600ms ease;
    transform: translateX(0);
    transform-origin: 100% 50%;
  }
  flex-wrap: wrap;
  .video1 {
    height: 88vh;
    width: 88vw;
    .no-photo {
      height: 120px;
      width: 120px;
    }
    video {
      margin-top: 15px;
      height: 100vh;
    }
  }
  .video2 {
    height: 42vh;
    width: 50vw;
    .no-photo {
      height: 120px;
      width: 120px;
    }
    video {
      max-height: calc(42vh - 8px);
    }
  }
  .video3,
  .video4 {
    height: 37vh;
    width: 37vw;
    .no-photo {
      max-height: 22vh;
      max-width: 22vh;
      h4 {
        font-size: 10vh !important;
      }
    }
  }
  .video5,
  .video6 {
    height: 33vh;
    width: 35vw !important;
    overflow: hidden;
    video {
      margin-top: 15px;
      max-height: calc(33vh - 8px);
    }
    .name-display {
      h5 {
        font-size: 16px;
      }
    }
    .no-photo {
      max-height: 15vh;
      max-width: 15vh;
      h4 {
        font-size: 8vh !important;
      }
    }
  }
  .video7,
  .video8 {
    height: 200px;
    width: 380px;
    margin: 5px 5px;
    .profile-wrapper {
      height: 200px;
      width: 380px;
    }
    .name-display {
      h5 {
        font-size: 14px;
      }
      img {
        margin-top: 0px !important;
        height: 16px !important;
      }
    }
    .no-photo {
      max-height: 11vh;
      max-width: 11vh;
      h4 {
        font-size: 6vh !important;
      }
    }
  }
  .video9,
  .video10 {
    height: 200px;
    width: 380px;
    margin: 10px 10px;
    .profile-wrapper {
      height: 200px;
      width: 380px;
    }
    .no-photo {
      max-height: 10vh;
      max-width: 10vh;
      h4 {
        font-size: 5vh !important;
      }
    }
    .name-display {
      margin-top: 0px;
      margin-left: -2px;
      h5 {
        margin-top: -3px;
        font-size: 11px;
      }
      img {
        margin-top: 0px !important;
        height: 14px !important;
      }
    }
  }
  &.twilio3,
  &.twilio4 {
    max-height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  &.twilio5,
  &.twilio6 {
    margin: auto;
    // max-width: 75vw;
    // margin-right: 12vw;
    &.hide-sharing {
      // margin-right: 12vw;
    }
    &.show-bookmark {
      // margin-right: 1vw;
    }
    &.show-sharing {
      margin-right: 1.5vw;
      margin-left: 1.5vw;
    }
  }
  &.twilio7,
  &.twilio8 {
    margin: auto;
    // max-width: 60%;
    &.hide-sharing {
      // margin-right: 18vw;
    }
    &.show-bookmark {
      // margin-right: 8vw;
    }
    &.show-sharing {
      margin-right: 1.5vw;
      margin-left: 1.5vw;
      width: 400px;
      max-height: 100vh;
    }
  }
  &.twilio9,
  &.twilio10 {
    margin: auto;
    // max-width: 75%;
    // max-height: 100vh;
    &.hide-sharing {
      // margin-right: 13vw;
    }
    &.show-bookmark {
      // margin-right: 2vw;
    }
    &.show-sharing {
      margin-right: 10px !important;
      margin-left: 10px !important;
      width: 510px;
      @media screen and (max-width: 1536px) {
        width: 400px;
        height: 600px;
        margin-top: 10px;
      }
      max-height: 100vh;
    }
  }
  // is sharing screen
  &.show-sharing {
    animation: show-share-screen 500ms ease;
    transform: translateX(0);
    margin-top: 40px;
  }
  &.hide-sharing {
    animation: hide-share-screen 500ms ease;
    transform: translateX(0);
  }
  &.sharing1,
  &.sharing2 {
    max-height: 100vh;
    max-width: 27vw;
    margin-right: 1vw;
    margin-left: 1vw;
    .video1,
    .video2 {
      height: 200px;
      max-width: 384px;
      .no-photo {
        height: 110px !important;
        width: 110px !important;
        h4 {
          font-size: 8vh !important;
        }
      }
    }
  }
  &.sharing3,
  &.sharing4 {
    max-height: 100vh;
    max-width: 27vw;
    margin-right: 1vw;
    margin-left: 1vw;
    .video3,
    .video4 {
      height: 200px;
      max-width: 384px;
      .no-photo {
        height: 110px !important;
        width: 110px !important;
        h4 {
          font-size: 5vh !important;
        }
      }
    }
  }
  &.sharing5,
  &.sharing6 {
    max-height: 100vh;
    max-width: 27vw;
    overflow: hidden;
    .video5,
    .video6 {
      height: 200px;
      max-width: 384px;
      .no-photo {
        height: 110px !important;
        width: 110px !important;
        h4 {
          font-size: 5vh !important;
        }
      }
    }
  }
  &.sharing7,
  &.sharing8 {
    max-height: 100vh;
    max-width: 27vw;
    overflow: hidden;
    .video7,
    .video8 {
      height: 200px;
      max-width: 384px;
      .no-photo {
        height: 110px !important;
        width: 110px !important;
        h4 {
          font-size: 5vh !important;
        }
      }
    }
  }
  &.sharing9,
  &.sharing10 {
    max-height: 100vh;
    max-width: 27vw;
    .video9,
    .video10 {
      height: 110px !important;
      width: 110px !important;
      .no-photo {
        height: 200px;
        max-width: 384px;
        h4 {
          font-size: 5vh !important;
        }
      }
    }
  }
  .twilio-video {
    // height: 100%;
    // width: 100%;
    border-radius: 20px;
    position: relative;
    &.is-talking {
      border: 4px solid $primary-yellow-color;
    }
    &.no-talking {
      border: 4px solid $dark-grey-color;
    }
    .name-display {
      position: absolute;
      top: 10px;
      width: 80%;
      h5 {
        font-family: $google-sans-font;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
        color: #fff;
        letter-spacing: 0.2px;
        z-index: 1000;
        overflow: hidden;
        text-overflow: ellipsis;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.3);
        white-space: nowrap;
      }
      img {
        margin-top: 3px;
        height: 20px;
      }
    }
    .profile-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .participant-mute {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        position: absolute;
        height: 18vh;
        width: 18vh;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 100%;
        padding: auto;
        opacity: 0;
        &:hover {
          opacity: 1;
        }
      }
      video {
        object-fit: contain !important;
        height: 100% !important;
        margin-top: 0px !important;
        width: 100% !important;
      }
      .no-photo {
        height: 25vh;
        width: 25vh;
        border-radius: 100%;
        background-color: $primary-yellow-color;
        h4 {
          font-size: 12vh;
          color: white;
        }
      }
    }
    .video-wrapper {
      margin-top: -40px;
    }
  }
}

// Menu
@keyframes menu-appear-animation {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes menu-disappear-animation {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}

.menu-wrapper {
  &.d-block {
    // animation: menu-appear-animation 300ms ease;
    transform: translateY(0px);
    height: 64px;
  }
  &.d-hide {
    height: 0px;
    animation: menu-disappear-animation 200ms ease;
    transform: translateY(-50px);
  }
  z-index: 3;
  background-color: $white-theme;
  // animation: menu-disappear-animation 300ms ease;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0px 0px 0px 12px;
  padding: 7px 20px 7px 20px;
  align-items: center;
  // &:hover {
  //   animation: menu-appear-animation 300ms ease;
  //   transform: translateY(50px);
  // }
  &.assessor {
    min-width: 710px;
  }
  .off {
    background-color: $grey-red-color;
    img {
      min-height: 17px;
    }
  }
  .sharing {
    background-color: $secondary-blue-color;
  }
  .note {
    background-color: $primary-blue-color;
  }
  .time-counter {
    position: relative;
    left: -29px;
    z-index: 2;
    background-color: $grey-two-color;
    color: $primary-blue-color;
    font-family: $primary-font;
    font-size: 18px;
    margin: auto;
    padding: 5px 91px 5px 9px;
    border-radius: 12px;
    p {
      margin: 0px;
      display: flex;
      align-items: center;
    }
  }
  .bookmark-wrapper {
    cursor: pointer;
    position: absolute;
    left: 126.21px;
    z-index: 3;
    margin-right: 6px;
    justify-content: center;
    align-items: center;
    padding: 8px 14px;
    background-color: $primary-blue-color;
    font-family: $primary-font;
    color: $white-theme;
    font-size: 14px;
    font-weight: 600;
    border-radius: 12px;
    img {
      height: 18px;
      margin-right: 8px;
    }
    p {
      margin: 0px;
    }
  }
  .icon-wrapper {
    cursor: pointer;
    border-radius: 1000px;
    border: solid 1px #c3d5df;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    margin: 0px 10px;
    padding: 10px;
    img {
      max-height: 15px;
    }
  }
  .meatball-icon {
    cursor: pointer;
    margin-left: 10px;
    padding-left: 20px;
    border-left: solid 1px $grey-blue-color;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chat-icon {
    margin-left: -4px;
    margin-right: 10px;
    padding-right: 10px;
    border-right: solid 1px $grey-blue-color;
    .unread {
      position: relative;
      top: 15px;
      left: 31px;
      background-color: #de350b;
      height: 6.5px;
      width: 6.5px;
      border-radius: 100%;
    }
  }
  .record-icon {
    width: 20px;
    margin-right: 5px;
    &.blink {
      animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
    }
    @keyframes blinker {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
  .record-peserta {
    margin: 0px 10px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.room-container {
  background: black;
  height: 100vh;
  overflow: hidden;
}

.twilio-wrapperv2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-wrap: wrap;
}

.twilio-videov2 {
  height: 100%;
  max-height: 400px;
  background: rgb(63, 95, 63);
  border: 2px solid grey;
}

.disabled {
  cursor: not-allowed !important;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
}

.timer-bookmark {
  font-size: 13px;
  background: #eee;
  padding: 5px 20px;
  color: #005589;

  .stringTime {
    vertical-align: middle;
    display: inline-block;
  }

  .record-icon {
    width: auto;
    height: 13px;
    margin-right: 5px;
    vertical-align: text-bottom;
    &.blink {
      animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
    }
    @keyframes blinker {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
}
