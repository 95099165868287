.loading-wrapper {
  height: 100vh;
  width: 100vvw;
  background-color: black;
}

.loading-spinner {
  margin: auto;
  display: inline-block;
  position: relative;
  top: calc(50vh - 40px);
  left: calc(50vw - 40px);
  width: 80px;
  height: 80px;
}

.loading-spinner div {
  position: absolute;
  width: 8px;
  height: 8px;
  background: $white-spinner;
  border-radius: 50%;
  animation: loading-spinner 1.2s linear infinite;
}

.loading-spinner div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.loading-spinner div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.loading-spinner div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.loading-spinner div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.loading-spinner div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.loading-spinner div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.loading-spinner div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.loading-spinner div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.loading-spinner div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.loading-spinner div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.loading-spinner div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.loading-spinner div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

.toast-loading {
  position: absolute;
  top: calc(50vh - 120px);
  left: 50vw;
  .MuiSnackbarContent-root {
    color: $fifth-blue-color;
    background-color: $grey-eighth-color;
    border: 1px solid $fifth-blue-color;
    font-family: $primary-font;
    font-size: 13px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }
  .MuiSnackbarContent-message {
    padding: 0px;
  }
}

.toast-loading-error {
  position: absolute;
  top: calc(50vh - 120px);
  left: 50vw;
  .MuiSnackbarContent-root {
    color: $red-color;
    background-color: $mistyrose;
    border: 1px solid $grey-red-color;
    font-family: $primary-font;
    font-size: 13px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }
  .MuiSnackbarContent-message {
    padding: 0px;
  }
}

.toast-loading-error-2 {
  position: absolute;
  top: 0;
  left: 50vw;
  .MuiSnackbarContent-root {
    color: $red-color;
    background-color: $mistyrose;
    border: 1px solid $grey-red-color;
    font-family: $primary-font;
    font-size: 16px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }
  .MuiSnackbarContent-message {
    padding: 0px;
  }
}

@keyframes loading-spinner {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
}

// button
.primary-btn {
  background-color: $primary-blue-color;
  border-radius: 4px !important;
  font-size: 15px;
  font-weight: 600;
  color: white;
  padding: 6px 14px;
  &:hover {
    color: $grey-blue-color;
  }
}

.cancel-btn {
  background-color: white;
  border-radius: 12px !important;
  font-size: 15px;
  font-weight: 400;
  color: $dark-grey-color;
}

.main-pad {
  padding: 20px;
}

.default-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px -2px rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-disabled {
  background-color: $grey-sixth-color;
  color: white;
  border-radius: 12px;
}

.close-menu {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  background-color: $primary-blue-color;
  color: $grey-blue-color;
  border-radius: 4px;
  &:hover {
    color: $grey-blue-color;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.network-quality {
  position: absolute;
  bottom: 20px;
  left: 30px;
  color: green;
  opacity: 0.4;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
  &__0 {
    color: red;
  }
  &__1 {
    color: orange;
  }
  &__2 {
    color: yellow;
  }
  &__3 {
    color: greenyellow;
  }
  &__4 {
    color: greenyellow;
  }
  &__5 {
    color: greenyellow;
  }
}
