.lobby-wrapper {
  background-color: $white-theme;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.refresh-container {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 1000;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiIconButton-colorSecondary {
    color: red;
  }

  &__hide {
    opacity: 0;
  }

  &:hover {
    opacity: 1;
    .refresh-icon {
      animation: rotate-refresh 400ms ease-in-out;
    }
  }
}

@keyframes rotate-refresh {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
