.find-and-replace-modal {
    position: absolute;
    z-index: 3;
    top: calc(50vh - 132px);
    background-color: white;
    left: 100px;
    width: 500px;
    height: 264px;
    font-family: $primary-font;
    border-radius: 12px;
    .modal-header {
        padding: 20px 21px 8px 21px;
        padding-bottom: 0px;
        font-size: 13px;
        font-weight: 700;
        border: 0px;
        margin-bottom: 8px;
        p {
            margin-bottom: 0px;
        }
        img {
            cursor: pointer;
        }
    }
    .modal-body {
        font-size: 13px;
        padding: 8px 21px 24px 21px;
        input {
            padding: 6px;
            margin-top: 5px;
            margin-bottom: 0px;
            min-width: 100%;
            height: 31px;
            background-color: $grey-three-color;
            outline: 0px;
            border: 0px;
            color: $dark-grey-color;
        }
        label {
            margin-top: 0px;
        }
        p {
            margin-bottom: 0px;
        }
        .navigation-icon {
            cursor: pointer;
            position: relative;
            left: -50px;
            top: 8px;
        }
    }
    .modal-footer {
        border-top: 0px;
        margin-top: 0px;
        padding-top: 0px;
    }
}