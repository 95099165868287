// font
$primary-font: "Open Sans";
$secondary-font: "Open Sans";
$roboto-font: 'Roboto', Arial,sans-serif;
$google-sans-font: 'Google Sans',Roboto,Arial,sans-serif;

// color
$dark-grey-color: #58595b;
$grey-blue-color: #c3d5df;
$grey-red-color: #dfc3c3;
$grey-two-color: #dedede;
$grey-three-color: #eeeeee;
$grey-four-color: #f9f9f9;
$grey-five-color: #dbdbdb;
$grey-sixth-color: #a5adba;
$grey-seventh-color: #d3d3d3;
$grey-eighth-color: #ebebeb;
$grey-header-text: #6c7293;
$grey-backdrop-color: #102344;
$grey-footer-review: #666666;
$grey-border-color: #c7ccd5;
$primary-blue-color: #005589;
$secondary-blue-color: #c3d4df;
$third-blue-color: #156efd;
$fourth-blue-color: #125589;
$fifth-blue-color: #0065ff;
$sixth-blue-color: #73aaff;
$seventh-blue-color: #ccdde7;
$sky-blue-color: #f8fdff;
$blue-footer-color: #001e42;
$blue-header-title: #0f65ff;
$primary-yellow-color: #fd9f28;
$primary-red-color: #df471e;
$white-theme: #f4f4f4;
$white-spinner: #e9edf1;
$black-menu-color: #444a58;
$red-color: #6e0107;
$mistyrose: #ffe4e1;

//mobile
$mobile-background-color: #EDF2F7;
$mobile-soft-grey: #A5ADBA;
$mobile-dark-grey: #58595B;
$mobile-text-peserta: #242424;

