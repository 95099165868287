.session-ends-feedback {
    font-family: $primary-font;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .feedback-title {
        margin-top: 55px;
        margin-bottom: 40px;
        max-width: 485px;
        overflow: auto;
        h1 {
            font-size: 32px;
            font-weight: 700;
            color: $primary-red-color;
        }
        p {
            margin-bottom: 0px;
        }
    }
    .feedback-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title {
            margin-bottom: 17px;
            font-size: 18px;
            color: $black-menu-color;
        }
        .MuiRating-root {
            margin: auto;
        }
        textarea {
            width: 413px;
            height: 209px;
            padding: 20px 18px;
            font-size: 14px;
            color: $grey-sixth-color;
            outline: 0px solid $grey-blue-color;
            border: 1px solid $grey-blue-color;
            border-radius: 12px;
        }
        button {
            padding: 8px 41.5px;
            font-size: 16px;
            align-self: flex-end;
        }   
    }
}