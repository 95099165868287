@keyframes show-share-screen {
  0% {
    transform: translateX(-300px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes hide-share-screen {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}

.shared-screen {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  p {
    margin: 16px;
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 600;
    color: white;
  }
  &.show-sharing {
    animation: show-share-screen 500ms ease;
    transform: translateX(0);
  }
  &.hide-sharing {
    animation: hide-share-screen 500ms ease;
    transform: translateX(0);
    max-height: 0px;
  }
  &.show-bookmark {
    max-width: calc(73vw - 332px);
    position: absolute;
    left: 0px;
  }
  .video-container {
    margin-left: 20px;
    display: flex;
    height: calc(100vh - 64px);
    width: calc(100vw - 32vw);
    video {
      width: 100%;
      object-fit: contain;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 1536px) {
  .name-display {
    top: 10px !important;
    left: -10px !important;
  }

  .trim-name {
    font-size: 0.7rem !important;
    // max-width: 75% !important;
  }

  // .name-display {
  //   img {
  //     height: 10px !important;
  //     // margin-top: 3px !important;
  //   }
  // }
}

@media screen and (max-width: 992px) {
  .trim-name {
    font-size: 0.7rem !important;
    // max-width: 60% !important;
  }
}

.show-bookmark {
  .show-sharing {
    .video-container {
      width: calc(100vw - 32vw - 240px);
      @media screen and (max-width: 1536px) {
        width: calc(100vw - 32vw - 300px);
      }
    }

    &.twilio-wrapper {
      margin-right: 1.5vw !important;
      margin-left: 1.5vw !important;
      right: 295px;
      @media screen and (max-width: 1536px) {
        right: 320px;
      }
    }
  }
}
