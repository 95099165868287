.session-ends-info {
    margin-top: 56px;
    font-family: $primary-font;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
        h1 {
            color: $black-menu-color;
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 50px;
        }
    }
    .content {
        background-color: white;
        width: 540px;
        overflow: auto;
        padding: 18px;
        border-radius: 12px;
        p {
            color: $black-menu-color;
            font-size: 16px;
            font-weight: 600;
        }
        .logo-icon {
            margin-top: 14px;
            margin-bottom: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .contacts {
            margin-top: 28px;
            .contact {
                margin-bottom: 12px;
                p {
                    margin-bottom: 0px;
                }
                img {
                    height: 20px;
                    margin-right: 12px;
                }
            }
        }
    }
}