.session-footer {
    background-color: $blue-footer-color;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $primary-font;
    font-size: 18px;
    font-weight: 700;
    color: white;
    position: absolute;
    bottom: 0px;
    width: 100vw;
    p {
        margin-bottom: 0px;
    }
    button {
        color: white;
        font-weight: 700;
        font-size: 18px;
        background-color: $primary-yellow-color;
        border-radius: 20px;
        width: 162px;
        margin-left: 20px;
        &:hover {
            color: white;
        }
    }

}