.fgd-modal {
    background-color: white;
    border-radius: 4px;
    width: 420px;
    position: absolute;
    // z-index: 5;
    z-index: 1;
    // left: calc(100vw - 420px);
    left: calc(100vw - 711px);
    top: 70px;
    font-family: $primary-font;
    .modal-header {
        font-family: $primary-font;
        color: $primary-blue-color;
        font-weight: 700;
        font-size: 15px;
        border-bottom: 1px solid $grey-blue-color;
        padding-bottom: 16px;
        padding-top: 16px;
        cursor: pointer;
        img {
            cursor: pointer;
        }
        p {
            margin-bottom: 0px;
        }
        &:active{
            cursor: move;
        }
    }
    .modal-body {
        padding: 16px;
        img {
            margin-bottom: 12px;
        }
        font-family: $primary-font;
        font-size: 13px;
    }
}